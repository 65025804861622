import React, {Component} from 'react';
import style from './results.module.scss';
import Card from 'components/card';
import Button from '../button/';
import parse from 'html-react-parser';

class Results extends Component {
	constructor(props) {
		super(props);
		this.resultsRef = React.createRef();
	}
	render() {
		const title = this.props.title||'';
		const intro = this.props.intro||'';
		const data = this.props.data;
		const hasMore = this.props.hasMore;
		const showFilters = this.props.showFilters;
		if (this.props.resultsFocus) {
			let offset = this.resultsRef.current.offsetTop-190;
			window.scroll({top: offset, left: 0, behavior: 'smooth' });
		}
		return (
			<div className={`${this.props.stuck ? style.stuck : ''} grid-container`}>
				<div ref={this.resultsRef} className={`${style.searchResults} grid-x ${data.length > 0 ? 'small-up-1 medium-up-2 large-up-4' : ''}`}>
					{(() => {
						switch (true) {
							case data.length > 0:
								return (
									data.map((card, index) => (
										<div key={index} className={`${style.cardHolder} cell`}>
											<Card type={card._model} data={card} title={card.title ? card.title : card.headline} image={card.photo ? card.photo : card.featuredImage} description={card.intro ? card.intro : card.excerpt} />
										</div>
									))
								)
							case data.length <= 0:
								return (
									<div className="cell small-12 text-center">
										<h4>You're search yielded no results</h4>
									</div>
								)
							default: return "No data";
						}
					})()}
				</div>
				{hasMore && <div className={`${style.loadMore} grid-x grid-margin-x`}>
					<div className="cell text-center small-12 medium-auto">
						<Button onClick={this.props.loadMore} className={`${style.gridUpdate} dark`} text="Load More" />
					</div>
				</div>}
			</div>
		)
	}
}

export default Results;