import React, {Component} from 'react'
import style from './select.module.scss';

class Select extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedValue: this.props.selectedValue||'',
		};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(evt) {
		this.setState({
			selectedValue: evt.target.value,
		})
	}

	render() {
		if(this.props.value) {
			var values = this.props.value;
			var type;
			if (Array.isArray(values)) {
				const item = values[0];
				const isArray = item instanceof Array;
				if (typeof item === 'string') {
					type = 'array'; 
				} else if(isArray === false) {
					type = 'object';
				}
			} else {
				type = typeof values;
			}
		}
		switch(type) {
			case 'string':
				return (
					<select onChange={this.handleChange} onBlur={this.props.changeHandler} aria-label={this.props.label} className={style.selectInput} value={this.state.selectedValue} name={this.props.name}>
						<option value="" disabled hidden>{this.props.placeholder}</option>
						<option value={this.props.value}>{this.props.value}</option>
						}
					</select>
				)
			case 'array':
				const options = values.map((value, index) =>
				  <option key={index} value={value}>{value}</option>
				);
				return (
					<select onChange={this.handleChange} onBlur={this.props.changeHandler} aria-label={this.props.label} className={style.selectInput} value={this.state.selectedValue} name={this.props.name}>
						<option disabled value="" hidden>{this.props.placeholder}</option>
						<option value="">All</option>
						{options}
					</select>
				)
			case 'object':
				const multiOptions = values.map((value, index) =>
				  <option key={index} value={value.value}>{value.label}</option>
				);
				return (
					<select onChange={this.handleChange} onBlur={this.props.changeHandler} aria-label={this.props.label} className={style.selectInput} value={this.state.selectedValue} name={this.props.name}>
						<option value="" disabled hidden>{this.props.placeholder}</option>
						<option value="">All</option>
						{multiOptions}
					</select>
				)
			default:
				return (
					<p>I need props!</p>
				)
		}
	}
}

export default Select;