import React, { Component } from 'react';
import style from './searchgrid.module.scss';
import SearchInput from 'components/searchinput/';
import Button from '../button/';
import Select from 'components/select/';
import Results from './results';
import Icon from 'components/icon';
import { graphql, StaticQuery } from 'gatsby'
import { connect } from 'react-redux'

class SearchGrid extends Component {
	constructor(props) {
		super(props);
		// this.searchBar = React.createRef();
		this.searchGrid = React.createRef();
		this.searchFilters = React.createRef();
		this.state = {
			gridUpdate: 'load',
			resultsFocus: false,
			searchQuery: null,
			trackingQuery: null,
			submitted: false,
			stuck: false,
			filterToggle: true,
			spacerStuck: false,
			data: this.props.data,
			hasMore: false,
			showFilters: ((props.apiController === 'events/event' || props.apiController === "news/report") ? false : true),
		}
		this.updateClick = this.updateClick.bind(this);
		this.loadMoreClick = this.loadMoreClick.bind(this);
		this.changeHandler = this.changeHandler.bind(this);
		this.trackScrolling = this.trackScrolling.bind(this);
		this.filterToggle = this.filterToggle.bind(this);
		this.apiBuild = this.apiBuild.bind(this);
		this.findEvent = this.findEvent.bind(this);
		this.keyDownHandler = this.keyDownHandler.bind(this);

		// This will be the API return, mock data for now
		this.resultData = [];
		this.loadNum = 1;
		this.data = [];
		this.initialData = [];
		this.cleanData = [];
		this.searchQuery = {};
		this.trackingQuery = {};
		this.pageQuery = `&_page=${this.loadNum}&_sort=showDate&_order=ASC`
		this.apiCall = '';
		this.apiUrl = process.env.GATSBY_ZMS_API_URL;
		this.apiController = 'aggregate';
		this.pageCat = null;
		this.neighborhood = null;
		this.page = null;
		this.type = null;
		this.allEvents = null;

		// We are storing all event data locally because the API is not inline with the front end
		this.allEventData = [];
		this.liveRefresh = this.liveRefresh.bind(this);
	}

	liveRefresh() {
		fetch(`${process.env.GATSBY_ZMS_API_URL}events/event/all/?_join=true`)
			.then(response => response.json())
			.then(({ data }) => {
				if (data.length) {
					this.allEvents = data;
				}
			})
			.catch(err => console.log);
	}

	isBottom(el) {
		return el.getBoundingClientRect().bottom;
	}

	isTop(el) {
		return el.getBoundingClientRect().top;
	}

	componentDidMount() {
		document.addEventListener('scroll', this.trackScrolling);
		this.page = window.location.pathname;
		this.initialData = this.props.initialData;

		if (this.props.useState !== true || this.props.data.length === 0) {
			this.loadNum = 1;
			if (this.props.apiController === 'events/event') {
				this.allEventData = [];
				this.initialData.forEach(node => {
					this.allEventData.push(node.node);
				});
			}
			if (this.initialData.length > 8 && this.initialData.length < 20) { this.setState({ hasMore: false }) } else if (this.initialData.length > 8) { this.setState({ hasMore: true }) };

			if (this.initialData.length >= 20) {
				if (this.props.apiController !== 'events/event') {
					this.setState({ showFilters: true });
				}
				this.initialData.length = 8;
			};
			this.initialData.forEach(node => {
				this.cleanData.push(node.node);
			});
			this.data = this.cleanData;
			// this.setSearchGridState(this.cleanData);
		}
		else {
			this.allEventData = this.props.allEventData || [];
			this.data = this.props.data;
			this.loadNum = (this.props.loadNum ? this.props.loadNum + 1 : 1);
			this.setState({
				hasMore: this.props.hasMore,
			});
		}
		if (this.props.apiController) {
			this.apiController = this.props.apiController;
		}
		this.apiCall = `${this.apiUrl}${this.apiController}/all/?_pageSize=8&_join=true&_sort=showDate&_order=ASC`;
		if (this.apiController === 'events/event') {
			this.apiCall += '&marquee=true';
		}
		if (this.props.category) {
			this.pageCat = this.props.category;
		}
		if (this.props.neighborhood) {
			this.neighborhood = this.props.neighborhood;
		}
		if (this.props.allEvents) {
			this.allEvents = this.props.allEvents;
		}
		if (this.props.searchQuery) {
			this.searchQuery = this.props.searchQuery;
		}
		if (this.props.defaultQuery) {
			this.searchQuery = this.props.defaultQuery;
		}
		this.cleanData = this.data;
		if (this.props.apiController === 'events/event') {
			this.setState({
				showFilters: false,
			});
		}
		this.setState({
			data: this.data,
			loadNum: this.loadNum,
			filters: this.props.filterOptions,
			searchQuery: this.searchQuery,
			allEventData: this.allEventData,
		});
		this.setSearchGridState();
		this.apiBuild();
		if (this.props.defaultQuery) {
			this.updateClick();
			this.setState({ gridUpdate: 'load', resultsFocus: false, submitted: false });
		}
	}

	componentWillUnmount() {
		document.removeEventListener('scroll', this.trackScrolling);
	}

	findEvent(id) {
		var singleEvent = {};
		this.allEvents.forEach((event, index) => {
			if (id === event.node.id) {
				if (event.node.dates.length > 0) {
					singleEvent.dates = event.node.dates;
				}
				if (event.node.Listing.length > 0) {
					singleEvent.Listing = event.node.Listing;
				}
				if (event.node._model.length > 0) {
					singleEvent._model = event.node._model;
				}
			}
		})
		return singleEvent;
	}

	apiBuild() {
		let query = [];
		if (this.pageCat) {
			query.push(`category=${this.pageCat}`);
		}
		if (this.neighborhood) {
			query.push(`neighborhood=${this.neighborhood}`);
		}
		if (this.type) {
			query.push(`type=${this.type}`);
		}
		if (query.length > 0) {
			query = `&${query.join('&')}`;
		}
		this.apiCall = `${this.apiUrl}${this.apiController}/all/?_pageSize=8&_join=true${query}&_sort=showDate&_order=DESC`;
		if (this.apiController === 'events/event') {
			this.apiCall += '&marquee=true';
		}
		query = this.searchQuery;
		for (const filter in query) {
			if (query[filter]) {
				this.apiCall += `&${filter}=${query[filter]}`;
			}
		}
		this.apiCall += `&_page=${this.loadNum}`;
	}

	trackScrolling = () => {
		const wrappedElement = this.searchGrid.current;
		var topTrigger = 20;
		var bottomTrigger = 170;
		if (this.props.alert) {
			topTrigger = 120;
			bottomTrigger = 240;
		}
		if ((wrappedElement.getBoundingClientRect().top <= topTrigger) && (wrappedElement.getBoundingClientRect().bottom >= bottomTrigger)) {
			if (!this.state.stuck) {
				this.setState({ stuck: true, resultsFocus: false });
			}
		} else {
			this.setState({ stuck: false, resultsFocus: false })
		}
		if (wrappedElement.getBoundingClientRect().top <= topTrigger) {
			this.setState({ spacerStuck: true, resultsFocus: false })
		} else {
			this.setState({ spacerStuck: false, resultsFocus: false })
		}
	};

	setSearchGridState(data) {
		this.props.setSearch({
			searchQuery: this.state.searchQuery,
			results: data || this.state.data,
			filters: this.state.filters,
			hasMore: this.state.hasMore,
			useState: true,
			loadNum: this.state.loadNum,
			allEventData: this.state.allEventData,
		});
	}

	updateClick() {
		window.dataLayer = window.dataLayer || [];
		var event = {};
		event.event = 'listingsSearch';
		event.action = 'search';
		event.page = this.page;
		event.category = 'Page Interaction';
		event.location = 'Listings Search Bar';
		event.text = 'Update';
		for (const property in this.trackingQuery) {
			event[property] = this.trackingQuery[property];
		}
		// event.query = this.trackingQuery;
		window.dataLayer.push(event);
		if (this.props.type) {
			this.type = this.props.type;
			this.apiController = 'search';
		}
		this.loadNum = 1;
		this.setState({
			loadNum: this.loadNum,
		});
		this.apiBuild();
		this.setState({ filterToggle: true });
		this.setState({ gridUpdate: 'replace', resultsFocus: true, submitted: true });
		/*
		let query = this.searchQuery;
		for (const filter in query) {
			if (query[filter]) {
				this.apiCall += `&${filter}=${query[filter]}`;
			}
		}
		let apiCall = `${this.apiCall}&_page=${this.loadNum}`;
		*/
		fetch(this.apiCall)
			.then(results => {
				return results.json();
			}).then(data => {
				var responseData = [];
				var responseDatum = {};
				data.data.forEach(datum => {
					let title = null;
					if (datum.title) {
						title = datum.title
					} else {
						title = datum.name
					}
					let photo = null;
					if (datum.photo) {
						photo = datum.photo
					} else {
						photo = datum.featuredImage
					}
					let description = null;
					if (datum.summary) {
						description = datum.summary
					} else if (datum.intro) {
						description = datum.intro
					} else {
						description = datum.description
					}
					if (this.props.apiController === 'events/event') {
						var dates = null;
						if (this.findEvent(datum.id).dates) {
							dates = this.findEvent(datum.id).dates;
						} else if (datum.dates) {
							dates = datum.dates;
						}
						var listing = null;
						if (datum.Listing) {
							listing = datum.Listing;
						} else if (this.findEvent(datum.id).Listing) {
							listing = this.findEvent(datum.id).Listing;
						}
					}
					var model = null;
					if (datum._model) {
						model = datum._model;
					} else if (datum.modelPath) {
						var split = datum.modelPath.split('.');
						model = split[1];
					}

					responseDatum = {
						id: datum.id,
						title: title ? title : datum.headline,
						photo: photo,
						description: description ? description : datum.excerpt,
						uri: datum.uri,
						permalink: datum.permalink,
						_model: model,
						Category: datum.Category,
						location: datum.location,
						showDate: datum.showDate,
					};
					responseData.push(responseDatum);
				})
				this.setState({ data: responseData });
				this.resultData = this.state.data;
				if (this.resultData.length > 5) { this.setState({ hasMore: true }) } else { this.setState({ hasMore: false }) };
				this.setSearchGridState();
			})
	}
	loadMoreClick() {
		window.dataLayer = window.dataLayer || [];
		var event = {};
		event.event = 'loadMoreListings';
		event.action = 'click';
		event.page = this.page;
		event.category = 'Page Interaction';
		event.location = 'Listings Grid';
		event.text = 'Load More';
		for (const property in this.trackingQuery) {
			event[property] = this.trackingQuery[property];
		}
		window.dataLayer.push(event);

		this.setState({ resultsFocus: false })
		this.loadNum += 1;
		this.setState({
			loadNum: this.loadNum,
		});
		this.apiBuild();
		// var apiCall = `${this.apiCall}&_page=${this.loadNum}`;
		if (this.props.apiController === 'events/event') { // Events backend does not load data in the same way so the paging is off, instead we'll use the local object and return what we want from it.
			var start = (this.loadNum - 1) * 8;
			var end = Math.min(start + 8, this.state.allEventData.length);
			this.cleanData = this.cleanData.concat(this.state.allEventData.slice(start, end));
			this.setState({
				data: this.cleanData,
				hasMore: (end < this.state.allEventData.length),
			});
			this.setSearchGridState(this.cleanData);
			return;
		}
		switch (this.state.gridUpdate) {
			case 'load':
				fetch(this.apiCall)
					.then(results => {
						return results.json();
					}).then(data => {
						if (data.data.length > 5) { this.setState({ hasMore: true }) } else { this.setState({ hasMore: false }) };
						let responseDatum = {};
						data.data.forEach(datum => {
							let title = '';
							if (datum.title) {
								title = datum.title
							} else {
								title = datum.name
							}

							let photo = datum.photo || datum.image || datum.featuredImage || '';
							let description = null;
							if (datum.summary) {
								description = datum.summary
							} else if (datum.intro) {
								description = datum.intro
							} else {
								description = datum.description
							}
							if (this.props.apiController === 'events/event') {
								var dates = null;
								if (this.findEvent(datum.id).dates) {
									dates = this.findEvent(datum.id).dates;
								} else if (datum.dates) {
									dates = datum.dates;
								}
								var listing = null;
								if (datum.Listing) {
									listing = datum.Listing;
								} else if (this.findEvent(datum.id).Listing) {
									listing = this.findEvent(datum.id).Listing;
								}
							}
							var model = null;
							if (datum._model) {
								model = datum._model;
							} else if (datum.modelPath) {
								var split = datum.modelPath.split('.');
								model = split[1];
							}

							responseDatum = {
								id: datum.id,
								title: title ? title : datum.headline,
								photo: photo,
								description: description ? description : datum.excerpt,
								uri: datum.uri,
								permalink: datum.permalink,
								_model: model,
								Category: datum.Category,
								location: datum.location,
								showDate: datum.showDate,
							};
							this.cleanData.push(responseDatum);
						})
						this.setState({ gridUpddate: 'load', data: this.cleanData })
						this.setSearchGridState();
					})
				break;
			case 'replace':
				fetch(this.apiCall)
					.then(results => {
						return results.json();
					}).then(data => {
						if (data.data.length > 5) { this.setState({ hasMore: true }) } else { this.setState({ hasMore: false }) };
						let responseDatum = {};
						data.data.forEach(datum => {
							let title = '';
							if (datum.title) {
								title = datum.title
							} else {
								title = datum.name
							}
							let photo = datum.photo || datum.image || datum.featuredImage || '';
							let description = null;
							if (datum.summary) {
								description = datum.summary
							} else if (datum.intro) {
								description = datum.intro
							} else {
								description = datum.description
							}
							if (this.props.apiController === 'events/event') {
								var dates = null;
								if (this.findEvent(datum.id).dates) {
									dates = this.findEvent(datum.id).dates;
								} else if (datum.dates) {
									dates = datum.dates;
								}
								var listing = null;
								if (datum.Listing) {
									listing = datum.Listing;
								} else if (this.findEvent(datum.id).Listing) {
									listing = this.findEvent(datum.id).Listing;
								}
							}
							var model = null;
							if (datum._model) {
								model = datum._model;
							} else if (datum.modelPath) {
								var split = datum.modelPath.split('.');
								model = split[1];
							}

							responseDatum = {
								id: datum.id,
								title: title ? title : datum.headline,
								photo: photo,
								description: description ? description : datum.excerpt,
								uri: datum.uri,
								permalink: datum.permalink,
								_model: model,
								Category: datum.Category,
								location: datum.location,
								showDate: datum.showDate,
							};
							this.resultData.push(responseDatum);
						})
						this.setState({ gridUpddate: 'replace', data: this.resultData })
						this.setSearchGridState();
					})
				break;
			default:
				fetch(this.apiCall)
					.then(results => {
						return results.json();
					}).then(data => {
						if (data.data.length > 5) { this.setState({ hasMore: true }) } else { this.setState({ hasMore: false }) };
						let responseDatum = {};
						data.data.forEach(datum => {
							let title = '';
							if (datum.title) {
								title = datum.title
							} else {
								title = datum.name
							}
							let photo = datum.photo || datum.image || datum.featuredImage || '';
							let description = null;
							if (datum.summary) {
								description = datum.summary
							} else if (datum.intro) {
								description = datum.intro
							} else {
								description = datum.description
							}
							var dates = null;
							if (this.findEvent(datum.id).dates) {
								dates = this.findEvent(datum.id).dates;
							} else if (datum.dates) {
								dates = datum.dates;
							}
							var listing = null;
							if (datum.Listing) {
								listing = datum.Listing;
							} else if (this.findEvent(datum.id).Listing) {
								listing = this.findEvent(datum.id).Listing;
							}
							var model = null;
							if (datum._model) {
								model = datum._model;
							} else if (datum.modelPath) {
								var split = datum.modelPath.split('.');
								model = split[1];
							}
							responseDatum = {
								id: datum.id,
								title: title ? title : datum.headline,
								photo: photo,
								description: description ? description : datum.excerpt,
								uri: datum.uri,
								permalink: datum.permalink,
								_model: model,
								Category: datum.Category,
								location: datum.location,
								showDate: datum.showDate,
							};
							this.cleanData.push(responseDatum);
						})
						this.setState({
							data: this.cleanData,
						});
						this.setSearchGridState();
					})
		}
	}

	changeHandler(e) {
		this.setState({ submitted: false, resultsFocus: false });
		let { options, name, value, selectedIndex } = e.target;

		// Search query for functionality
		var trackingValue = value;
		if (typeof value === 'string') {
			value = encodeURI(value);
		}
		if (this.searchQuery) {
			this.searchQuery = { ...this.state.searchQuery, ...{ [name]: value } }
			this.setState({ searchQuery: this.searchQuery });
		} else {
			this.setState({
				searchQuery: { [name]: value },
			})
		}

		// Search query for tracking purposes
		if (name !== 'terms') {
			trackingValue = options[selectedIndex].innerHTML;
		}
		if (this.trackingQuery) {
			this.trackingQuery = { ...this.state.trackingQuery, ...{ [name]: trackingValue } }
			this.setState({ trackingQuery: this.trackingQuery });
		} else {
			this.setState({
				trackingQuery: { [name]: value },
			})
		}
	}

	keyDownHandler(evt) {
		if (evt.key === 'Enter') {
			this.updateClick();
		}
	}

	filterToggle() {
		if (this.state.filterToggle === true) {
			this.setState({ filterToggle: false });
		} else {
			this.setState({ filterToggle: true });
		}
	}

	render() {

		// This will be passed as props, eventually
		const filterOptions = this.state.filters || [];
		const numFilters = filterOptions.length;
		var spacerClass = `filters${numFilters}`;
		return (
			<section ref={this.searchGrid} id="searchGridContainer" className={`${style.searchGridContainer} ${this.state.showFilters ? '' : style.noFilters}`}>
				{this.state.showFilters ? <div className={`${style.spaceHolder} ${style[`${spacerClass}`]} ${this.state.spacerStuck ? style.stuck : ''} ${this.state.filterToggle ? style.collapsed : style.expanded}`}>
					<div ref={this.searchFilters} className={`${style.searchFiltersWrapper} ${this.state.stuck ? style.stuck : ''} ${this.props.alert ? style.alertOn : ''} full grid-container`}>
						<div className={`${style.filterToggleWrapper} hide-for-medium grid-x grid-margin-x`}>
							<div className={`${style.filterToggleWrapperInner} cell small-12`}>
								<div className={style.mobileCTA}>
									{this.props.searchCTA}
								</div>
								<button onClick={this.filterToggle} className={style.filterToggle}>
									<Icon icon={this.state.filterToggle ? 'downChevron' : 'upChevron'} />
								</button>
							</div>
						</div>
						<div ref={this.props.innerRef} className={`${style.searchFilters} ${this.state.filterToggle ? style.collapsed : style.expanded} grid-container`}>
							<div className="grid-x grid-margin-x">
								<div className={`${style.filterInput} cell small-12 ${filterOptions.length >= 3 ? 'medium-4 large-5' : 'medium-6'}`}>
									<SearchInput keyDownHandler={this.keyDownHandler} changeHandler={this.changeHandler} focusState={this.props.focusState} name="terms" value={this.state.searchQuery && this.state.searchQuery.terms ? decodeURI(this.state.searchQuery.terms) : ''} label={this.props.searchCTA} />
								</div>
								{filterOptions && filterOptions.map((filterOption, index) => (
									<div key={index} className="cell small-12 medium-auto">
										<div className="dropdown">
											<Select changeHandler={this.changeHandler} label={filterOption.label} placeholder={filterOption.placeholder} name={filterOption.name} selectedValue={this.state.searchQuery[filterOption.name]} value={filterOption.options} />
										</div>
									</div>
								))}
								<div className="cell small-12 medium-auto">
									<Button onClick={this.updateClick} className={`${style.gridUpdate} primary`} text="Update" />
								</div>
							</div>
						</div>
					</div>
				</div> : <div style={{ paddingTop: "5.25rem" }}></div>}
				<Results callout={this.props.callout} showFilters={this.state.showFilters} hasMore={this.state.hasMore} stuck={this.state.stuck} loadMore={this.loadMoreClick} resultsFocus={this.state.resultsFocus} data={this.state.data} category={this.props.category} title={this.props.title} intro={this.props.description} passState={this.state} />
			</section>
		)
	}
}

const mapStateToProps = (state, props) => {
	let newProps = {
		alert: state.alert,
		data: state.grid.results,
		filters: state.grid.filters,
		useState: state.grid.useState,
		hasMore: state.grid.hasMore,
		loadNum: state.grid.loadNum,
		searchQuery: state.grid.searchQuery,
		allEventData: state.grid.allEventData,
	}
	return newProps;
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		setSearch: (values) => dispatch({
			type: 'SET_SEARCHGRID_RESULTS',
			...values,
		}),
	};
};

SearchGrid = connect(mapStateToProps, mapDispatchToProps)(SearchGrid);
export default SearchGrid;